body {
  margin: 0;
    font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.content-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

a {
  text-decoration: none;
}

.img-fit {
  object-fit: cover;
}

.cursor {
  cursor: pointer;
}

.react-calendar {
  width: 100% !important;
}
.pragraph{
letter-spacing: .3rem;
}
